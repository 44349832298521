// import something here
import axios from 'axios'
import { Cookies, Dialog } from 'quasar'

class HHAPI {
  constructor () {
    this.url = 'https://api.helperhelper.com/3.0/service.php';
    this.uploadURL = 'https://api.helperhelper.com/3.0/upload.php';
    this.adminURL = 'https://admin.helperhelper.com/';
    this.appURL = 'https://app.helperhelper.com';
    if (process.env.DEV || process.env.DEBUGGING) {
      this.url = 'https://dev-api.helperhelper.com/3.0/service.php';
      this.uploadURL = 'https://dev-api.helperhelper.com/3.0/upload.php';
      this.adminURL = 'https://dev-admin.helperhelper.com/';
      this.appURL = 'https://dev-app.helperhelper.com'; // process.env.DEV ? 'http://dev-app.helperhelper.com:8080' : 'https://dev-app.helperhelper.com';
    }
    this.session = null;
    this.interfaceVersion = '3.25';
    this.clientIdentifier = 'HHWeb/1.0';
    if (navigator && navigator.userAgent) {
      this.clientIdentifier += ' ' + navigator.userAgent;
    }
    this.clientIdentifier += ' web';
  }

  isTemporarySession () {
    if (!this.session) { return false; }
    if (this.session.temporary > 0) { return true; }
    return false;
  }

  isSingleSignOnSession () {
    if (!this.session) { return false; }
    if (this.session.singlesignon > 0) { return true; }
    return false;
  }

  isAdminSession () {
    if (!this.session) { return false; }
    if (this.session.isadmin > 0) { return true; }
    return false;
  }

  // Once they change the temporary password, remove the temporary part of session
  temporaryPasswordChanged () {
    this.session = null;
  }

  attemptLoginRefresh () {
    var authcode = Cookies.get('hhtoken');
    api.loginWithAuthcode(authcode);
  }

  loginEmailQuery (email, redirect) {
    var apiData = this._constructAction('user-loginemail');
    apiData.user = { login: email };
    if (redirect !== '') { apiData.redirect = redirect; }
    this._typicalAPICall(apiData, 'emailLoginDetails', 'redirect');
  }

  loginWithPassword (login, password) {
    this._login(login, password, null);
  }

  loginWithAuthcode (authcode) {
    if (!authcode) {
      this.$events.$emit('loginRefreshFailed');
      return;
    }

    this._login(null, null, authcode);
  }

  logout () {
    var apiData = this._constructAction('user-logout');

    this.session = null;
    this._setAuthCookie('');
    this.$events.$emit('userLoggedOut');

    // Logout this session on the server, which may return a redirect url
    // if this user authenticated with single sign on.
    this._postAPICall(apiData).then(function (response) {
      if (process.env.DEV || process.env.DEBUGGING) {
        console.log('received', JSON.parse(JSON.stringify(response.data)));
      }

      if (response && 'data' in response && 'redirect' in response.data) {
        if (response.data.redirect !== '') {
          window.location = response.data.redirect;
        }
      }
    });
  }

  forgotPassword (email, sendViaSMS) {
    var apiData = this._constructAction('forgot-password');
    apiData.user = {
      login: email,
      resetpwsms: sendViaSMS ? 1 : 0
    };
    this._typicalAPICall(apiData, 'temporaryPasswordSent');
  }

  adminGetInstitutionUserProfile (institutionID, userID) {
    var apiData = this._constructAction('admin-user-profile-get');
    apiData.institutionid = institutionID;
    apiData.userid = userID;
    this._typicalAPICall(apiData, 'userProfileDetails', 'survey');
  }

  adminUpdateInstitutionUserProfile (institutionID, userID, surveyResponse) {
    var apiData = this._constructAction('admin-user-profile-update');
    apiData.institutionid = institutionID;
    apiData.userid = userID;
    apiData.survey = surveyResponse;
    this._typicalAPICall(apiData, 'userProfileUpdated', 'survey');
  }

  adminGetSurveyPreview (surveyID) {
    var apiData = this._constructAction('admin-survey-preview');
    apiData.survey = { id: surveyID };
    this._typicalAPICall(apiData, 'surveyPreviewDetails', 'survey');
  }

  adminGetCommitmentSurveys (commitmentID, isOrgAdmin) {
    var apiData = this._constructAction('admin-commitment-surveys-get');
    apiData.commitment = { id: commitmentID };
    if (isOrgAdmin) { apiData.admintype = 'org'; }
    this._typicalAPICall(apiData, 'adminCommitmentSurveysReceived',
      ['institution', 'user', 'opportunity', 'timeslot', 'commitment', 'surveys']);
  }

  adminUpdateCommitmentSurveys (commitmentID, surveyResponses, isOrgAdmin) {
    var apiData = this._constructAction('admin-commitment-surveys-update');
    apiData.commitment = { id: commitmentID, surveys: surveyResponses };
    if (isOrgAdmin) { apiData.admintype = 'org'; }
    this._typicalAPICall(apiData, 'adminCommitmentSurveysUpdated', '');
  }

  adminGetGoalAwardDetails (institutionID, goalID, goalItemType, goalItemID) {
    var apiData = this._constructAction('admin-goal-award-get');
    apiData.institutionid = institutionID;
    apiData.id = goalID;
    apiData[goalItemType + 'id'] = goalItemID;

    this._typicalAPICall(apiData, 'adminGoalAwardDetails', 'goal');
  }

  getInstitutionUserProfile (institutionID) {
    var apiData = this._constructAction('user-profile-get');
    apiData.institutionid = institutionID;
    this._typicalAPICall(apiData, 'userProfileDetails', 'survey');
  }

  updateInstitutionUserProfile (institutionID, surveyResponse) {
    var apiData = this._constructAction('user-profile-update');
    apiData.institutionid = institutionID;
    apiData.survey = surveyResponse;
    this._typicalAPICall(apiData, 'userProfileUpdated', 'survey');
  }

  getInstitutionSurveysAvailable () {
    var apiData = this._constructAction('institutionsurvey-list');
    this._typicalAPICall(apiData, 'institutionSurveysAvailableListed');
  }

  updateInstitutionSurvey (surveyResponse) {
    var apiData = this._constructAction('institutionsurvey-update');
    apiData.survey = surveyResponse;
    this._typicalAPICall(apiData, 'institutionSurveyUpdated', ['user', 'survey']);
  }

  getInstitutionSurveyResponses () {
    var apiData = this._constructAction('institutionsurvey-responses');
    this._typicalAPICall(apiData, 'institutionSurveyResponsesListed', 'surveys');
  }

  getPublicInstitutionInfo (institutionID) {
    var apiData = this._constructAction('institution-public-get');
    apiData.institutionid = institutionID;
    this._typicalAPICall(apiData, 'publicInstitutionInfo', 'institution');
  }

  getPublicInstitutionDetails (institutionID, loadDetails) {
    var apiData = this._constructAction('institution-signup-get');
    apiData.institutionid = institutionID;
    apiData.instloadpublic = loadDetails;
    this._typicalAPICall(apiData, 'publicInstitutionDetails', ['institution', 'opportunities', 'timeslots', 'teams']);
  }

  listPublicInstitutionOpportunities (institutionID, fetchOngoing) {
    var apiData = this._constructAction('institution-opportunities-list');
    apiData.institutionid = institutionID;
    apiData.instloadpublic = { opportunities: 1, ongoingopps: fetchOngoing ? 1 : 0 };
    this._typicalAPICall(apiData, 'publicOpportunitiesListed',
      ['institution', 'opportunities', 'timeslots', 'commitments', 'ongoingcount']);
  }

  getPublicInstitutionOpportunity (institutionID, opportunityID) {
    var apiData = this._constructAction('institution-opportunity-get');
    apiData.institutionid = institutionID;
    apiData.instloadpublic = { opportunity: opportunityID };
    this._typicalAPICall(apiData, 'publicOpportunityReceived',
      ['institution', 'opportunities', 'timeslots', 'commitments']);
  }

  getReaderInstitutionDetails (readerID) {
    var apiData = this._constructAction('institution-reader-get');
    apiData.readerid = readerID;
    this._typicalAPICall(apiData, 'readerInstitutionDetails', ['institution', 'readerconfig']);
  }

  getReaderInstitutionDetailsForUser (readerID, userID, userKey) {
    var apiData = this._constructAction('institution-reader-user-get');
    apiData.readerid = readerID;
    apiData.readeruser = { id: userID, key: userKey };
    this._typicalAPICall(apiData, 'readerInstitutionUserDetails', ['institution', 'readerconfig', 'readeruser']);
  }

  registerReaderSignup (readerID, readerSignup) {
    var apiData = this._constructAction('institution-reader-signup');
    apiData.readerid = readerID;
    apiData.readersignup = readerSignup;
    this._typicalAPICall(apiData, 'readerInstitutionSignup', 'readersignup');
  }

  registerReaderRemind (readerID, readerEmail) {
    var apiData = this._constructAction('institution-reader-remind');
    apiData.readerid = readerID;
    apiData.readersignup = { user: { email: readerEmail } };
    this._typicalAPICall(apiData, 'readerInstitutionRemind', 'readersignup');
  }

  updateReaderRecordForUser (readerID, userID, userKey, readerData) {
    var apiData = this._constructAction('institution-reader-user-record');
    apiData.readerid = readerID;
    apiData.readeruser = { id: userID, key: userKey };
    apiData.readerdata = readerData;
    this._typicalAPICall(apiData, 'readerInstitutionUserRecorded', ['institution', 'readerconfig', 'readeruser']);
  }

  updateReaderSettingsForUser (readerID, userID, userKey, readerData) {
    var apiData = this._constructAction('institution-reader-user-settings');
    apiData.readerid = readerID;
    apiData.readeruser = { id: userID, key: userKey };
    apiData.readerdata = readerData;
    this._typicalAPICall(apiData, 'readerInstitutionUserUpdated', ['institution', 'readerconfig', 'readeruser']);
  }

  addInstitutionUser (institutionID, newUser, newCommitment, teamID) {
    var apiData = this._constructAction('institution-signup-add');
    apiData.institutionid = institutionID;
    apiData.user = newUser;
    if (newCommitment) { apiData.commitment = newCommitment; }
    if (teamID) { apiData.teamid = teamID; }
    this._typicalAPICall(apiData, 'institutionUserAdded');
  }

  confirmInstitutionUser (institutionID, signupID, confirmUser) {
    var apiData = this._constructAction('institution-signup-confirm');
    apiData.institutionid = institutionID;
    apiData.signupid = signupID;
    apiData.user = confirmUser;
    this._typicalAPICall(apiData, 'institutionUserConfirmed', ['institution', 'session', 'user', 'survey', 'surveys',
      'opportunity', 'timeslot', 'commitment', 'team', 'opportunities', 'timeslots', 'commitments']);
  }

  listOpportunities () {
    var apiData = this._constructAction('opportunities-list');
    this._typicalAPICall(apiData, 'opportunitiesListed', ['opportunities', 'timeslots', 'commitments', 'ongoingcount']);
  }

  listOpportunitiesOngoing () {
    var apiData = this._constructAction('opportunities-listongoing');
    this._typicalAPICall(apiData, 'opportunitiesOngoingListed', 'opportunities');
  }

  listOpportunitiesForAdminCheckin (institutionID) {
    var apiData = this._constructAction('opportunities-listforadmincheckin');
    apiData.institutionid = institutionID;
    this._typicalAPICall(apiData, 'opportunitiesListedForAdminCheckin', ['opportunities', 'timeslots']);
  }

  getOpportunityDetailsForAdminCheckin (opportunityID, coordinatorManagementKey) {
    var apiData = this._constructAction('opportunity-getforadmincheckin');
    apiData.opportunityid = opportunityID;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    this._typicalAPICall(apiData, 'opportunityDetailsForAdminCheckin',
      ['institution', 'opportunity', 'timeslots', 'commitments']);
  }

  getOpportunityQRCodeForAdminCheckin (opportunityID, coordinatorManagementKey) {
    var apiData = this._constructAction('opportunity-qrcodeforadmincheckin');
    apiData.opportunityid = opportunityID;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    this._typicalAPICall(apiData, 'opportunityQRCodeForAdminCheckin', 'qrcode');
  }

  adminCheckinCommitment (commitment, coordinatorManagementKey) {
    var apiData = this._constructAction('commitment-admincheckin');
    apiData.commitment = commitment;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    this._typicalAPICall(apiData, 'commitmentAdminCheckin', 'commitment');
  }

  adminCheckinRemove (commitment, coordinatorManagementKey) {
    var apiData = this._constructAction('commitment-admincheckinremove');
    apiData.commitment = commitment;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    this._typicalAPICall(apiData, 'commitmentAdminCheckinRemoved', 'commitment');
  }

  adminCheckinAddCommitment (commitment, userID, opportunityID, coordinatorManagementKey) {
    var apiData = this._constructAction('commitment-admincheckinadd');
    apiData.user = { id: userID };
    apiData.commitment = commitment;
    apiData.opportunityid = opportunityID;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    this._typicalAPICall(apiData, 'commitmentAdminCheckinAdded', 'commitment');
  }

  adminCheckinSearch (opportunityID, userSearch, coordinatorManagementKey) {
    var apiData = this._constructAction('commitment-admincheckinsearch');
    apiData.opportunityid = opportunityID;
    if (coordinatorManagementKey !== '') {
      apiData.opportunitykey = coordinatorManagementKey;
    }
    apiData.search = userSearch;
    this._typicalAPICall(apiData, 'commitmentAdminCheckinSearched', 'users');
  }

  getOpportunityDetails (opportunityID) {
    var apiData = this._constructAction('opportunity-detail');
    apiData.opportunityid = opportunityID;
    this._typicalAPICall(apiData, 'opportunityDetails', ['opportunity', 'timeslots', 'commitments']);
  }

  getOpportunityCheckin (opportunityID, checkinParams, checkinSurveyResponses) {
    var apiData = this._constructAction('opportunity-checkin');
    apiData.opportunityid = opportunityID;
    if (checkinParams) { apiData.params = checkinParams; }
    if (checkinSurveyResponses) { apiData.commitment = { surveys: checkinSurveyResponses }; }

    this._typicalAPICall(apiData, 'opportunityCheckin', ['opportunity', 'timeslots', 'commitments', 'commitment', 'note']);
  }

  listTimeslotParticipants (timeslotID) {
    var apiData = this._constructAction('timeslot-participants');
    apiData.id = timeslotID;
    this._typicalAPICall(apiData, 'timeslotParticipantsListed', 'timeslot');
  }

  listCommitments () {
    var apiData = this._constructAction('commitment-list')
    this._typicalAPICall(apiData, 'commitmentsListed', ['timeslots', 'opportunities', 'commitments']);
  }

  getCommitment (commitmentID) {
    var apiData = this._constructAction('commitment-get');
    apiData.commitment = { id: commitmentID };
    this._typicalAPICall(apiData, 'commitmentDetails', ['timeslot', 'opportunity', 'commitment']);
  }

  addCommitment (commitment) {
    var apiData = this._constructAction('commitment-add');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'commitmentAdded', ['timeslots', 'opportunities', 'commitments']);
  }

  updateCommitment (commitment) {
    var apiData = this._constructAction('commitment-update');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'commitmentUpdated', ['timeslot', 'opportunity', 'commitment']);
  }

  updateCommitmentSurveys (commitment) {
    var apiData = this._constructAction('commitment-surveys-update');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'commitmentSurveysUpdated', ['timeslot', 'opportunity', 'commitment']);
  }

  deleteCommitmentPhoto (commitmentID, photoID) {
    var apiData = this._constructAction('commitment-upload-delete');
    apiData.commitment = { id: commitmentID, photos: [{ id: photoID }] };
    this._typicalAPICall(apiData, 'commitmentPhotoDeleted', ['timeslot', 'opportunity', 'commitment']);
  }

  deleteCommitment (commitment) {
    var apiData = this._constructAction('commitment-delete');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'commitmentDeleted', ['timeslot', 'opportunity']);
  }

  getGoalAwardDetails (goalID, teamID, branchID) {
    var apiData = this._constructAction('goal-award-get');
    apiData.id = goalID;
    if (teamID) { apiData.teamid = teamID; }
    if (branchID) { apiData.branchid = branchID; }
    this._typicalAPICall(apiData, 'goalAwardDetails', 'goal');
  }

  listPastCommitmentOpportunities (date) {
    var apiData = this._constructAction('pastcommitment-opportunities');
    apiData.commitment = { start: date };
    this._typicalAPICall(apiData, 'pastCommitmentOpportunitiesListed', ['timeslots', 'opportunities', 'commitments']);
  }

  // commitment should contain either 'id' or 'ispast' properties...
  getVolunteerAddedCommitmentSettings (commitment) {
    var apiData = this._constructAction('volunteeradded-settings');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'volunteerAddedSettings', ['organizations', 'instcategories', 'opportunity']);
  }

  addPastCommitment (commitment) {
    var apiData = this._constructAction('pastcommitment-add');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'pastCommitmentAdded', ['timeslot', 'opportunity', 'commitment']);
  }

  updatePastCommitment (commitment) {
    var apiData = this._constructAction('volunteeradded-update');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'pastCommitmentUpdated', ['timeslot', 'opportunity', 'commitment']);
  }

  deletePastCommitment (commitment) {
    var apiData = this._constructAction('volunteeradded-delete');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'pastCommitmentDeleted');
  }

  requestPersonalVTO (commitment) {
    var apiData = this._constructAction('vtorequest-add');
    apiData.commitment = commitment;
    this._typicalAPICall(apiData, 'personalVTORequested', ['timeslot', 'opportunity', 'commitment']);
  }

  updateUser (user) {
    var apiData = this._constructAction('user-update');
    apiData.user = user;
    this._typicalAPICall(apiData, 'userUpdated', 'user');
  }

  userReport () {
    var apiData = this._constructAction('report-user');
    this._typicalAPICall(apiData, 'userReportReceived', 'report');
  }

  generateServicePortfolio () {
    var apiData = this._constructAction('user-portfolio');
    this._typicalAPICall(apiData, 'userPortfolioGenerated', 'redirect');
  }

  contactUnsubscribeEmailForMessageType (email, type) {
    var apiData = this._constructAction('contact-unsubscribe');
    apiData.unsubscribe = {
      type: type,
      email: email
    };
    this._typicalAPICall(apiData, 'contactUnsubscribed');
  }

  loadCountryPhoneInfo () {
    if (this.$store.getters.isLoadingCountryPhoneInfo) { return; }
    this.$store.commit('setLoadingCountryPhoneInfo', true);

    var apiData = this._constructAction('countries-phone');
    this._typicalAPICall(apiData, 'countryPhoneInfoReceived', 'countries');
  }

  logAppError (error) {
    var apiData = this._constructAction('log-error');
    apiData.error = error;
    this._typicalAPICall(apiData, 'errorLogged');
  }

  uploadFileAttachment (apiData, file, fileCallbackID) {
    var context = this;
    var formData = new FormData();
    formData.append('json', JSON.stringify(apiData));
    formData.append('file', file);

    const source = axios.CancelToken.source();
    context.$events.$emit('fileUploadCancelToken', fileCallbackID, source);

    if (process.env.DEV || process.env.DEBUGGING) {
      console.log('sending', JSON.parse(JSON.stringify(apiData)));
    }

    axios.post(
      this.uploadURL,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: function (progressEvent) {
          var progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          context.$events.$emit('fileUploadProgress', fileCallbackID, progress);
        }
      }
    ).then(function (response) {
      if (process.env.DEV || process.env.DEBUGGING) {
        console.log('received', JSON.parse(JSON.stringify(response.data)));
      }

      if (!response.data || response.data.error) {
        var errorMessage = 'Upload failed';
        if (response.data && 'error' in response.data && 'message' in response.data.error) {
          errorMessage = response.data.error.message;
        }

        context.$events.$emit('fileUploadError', fileCallbackID, errorMessage);
        return;
      }

      context.$events.$emit('fileUploadSuccess', fileCallbackID, response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) { return; } // canceled
      context.$events.$emit('fileUploadError', fileCallbackID, 'Upload failed');
    });
  }

  impactReport (institutionID, branchID, teamID, startDate) {
    var apiData = this._constructAction('report-impact');
    apiData.institutionid = institutionID;
    if (branchID) { apiData.branchid = branchID; }
    else if (teamID) { apiData.teamid = teamID; }
    if (startDate) { apiData.start = startDate; }
    this._typicalAPICall(apiData, 'teamsReportReceived', 'report');
  }

  _login (login, password, authcode) {
    var apiData = this._constructAction('user-login')
    if (authcode) {
      apiData.session.authcode = authcode;
    }
    else {
      delete apiData.session.authcode;
      apiData.user = {
        login: login,
        password: password
      };
    }

    var context = this;
    this._postAPICall(apiData).then(function (response) {
      if (process.env.DEV || process.env.DEBUGGING) {
        console.log('received', JSON.parse(JSON.stringify(response.data)));
      }

      if (!response.data || response.data.error ||
          !response.data.session || !response.data.session.authcode ||
          !response.data.user || !response.data.user.id) {
        // This was an attempt to re-validate a session authcode
        if (authcode) {
          context._setAuthCookie('');
          context.$events.$emit('loginRefreshFailed');
          return;
        }

        // This was a failed login attempt with username/password
        context._emitError(response.data);
        return;
      }

      context.session = response.data.session;

      context._setAuthCookie(context.session.authcode);

      context.$events.$emit('userLoggedIn', response.data);
      if ('surveys' in response.data) { context.$store.commit('cacheSurveyDetails', response.data); }
    });
  }

  _setAuthCookie (authcode) {
    if (process.env.DEV) { Cookies.set('hhtoken', authcode, { path: '/', sameSite: 'Lax' }); }
    else {
      Cookies.set('hhtoken', authcode, { path: '/', domain: '.helperhelper.com', secure: true });
    }
  }

  _constructAction (command) {
    var d = new Date();
    var action = {
      api: {
        version: this.interfaceVersion,
        command: command
      },
      session: {
        clientIdentifier: this.clientIdentifier,
        clientTimestamp: d.toISOString(),
        clientTZOffset: d.getTimezoneOffset()
      }
    };

    if (this.session && this.session.authcode) {
      action.session.authcode = this.session.authcode;
    }

    return action;
  }

  _postAPICall (payload) {
    if (process.env.DEV || process.env.DEBUGGING) {
      console.log('sending', JSON.parse(JSON.stringify(payload)));
    }

    return axios.post(
      this.url,
      payload
    );
  }

  _emitError (responseData) {
    if ('error' in responseData && 'message' in responseData.error) {
      var errorTitle = 'Request Failed';
      if ('title' in responseData.error && responseData.error.title !== '') {
        errorTitle = responseData.error.title;
      }

      Dialog.create({
        title: errorTitle,
        message: responseData.error.message,
        style: 'white-space: pre-wrap'
      });
    }

    this.$events.$emit('apiError', responseData);

    // If the user's authentication has expired, emit that event and plugins/hhAuth.js will direct them properly
    if ('error' in responseData && 'code' in responseData.error && parseInt(responseData.error.code) === -1) {
      this._setAuthCookie('');
      this.$events.$emit('loginAuthcodeExpired');
    }
  }

  // Emits apiError if there was a problem with the request.
  // Otherwise, emits eventName with a payload defined by eventDataFields
  //   if eventDataFields is a string, just that object from response
  //   if eventDataFields is an array, an object containing those named objects from the response
  _typicalAPICall (apiData, eventName, eventDataFields) {
    var context = this;
    this._postAPICall(apiData).then(function (response) {
      if (apiData.api.command === 'countries-phone') { context.$store.commit('setLoadingCountryPhoneInfo', false); }

      if (process.env.DEV || process.env.DEBUGGING) {
        console.log('received', JSON.parse(JSON.stringify(response.data)));
      }

      if (!response.data || response.data.error) {
        context._emitError(response.data);
        return;
      }

      if ('instvto' in response.data) {
        context.$events.$emit('instVTOAvailableUpdated', response.data.instvto);
      }

      if ('surveys' in response.data) {
        context.$store.commit('cacheSurveyDetails', { surveys: response.data.surveys });
      }

      if ('user' in response.data) {
        // If the user's goal awards have been updated due to any API behavior, update them.
        if ('goals' in response.data.user) {
          context.$store.commit('updateUserGoals', response.data.user.goals);
        }

        // If the user's available surveys have been updated due to any API behavior, update them.
        if ('surveys' in response.data.user) {
          context.$store.commit('updateUserInstitutionSurveysAvailable', response.data.user.surveys);
        }
      }

      if (apiData.api.command === 'countries-phone') {
        context.$store.commit('setCountryPhoneInfo', response.data.countries);
      }

      if (!eventDataFields) {
        context.$events.$emit(eventName, null, response.data.request);
      }
      else if (typeof eventDataFields === 'string' || eventDataFields instanceof String) {
        context.$events.$emit(eventName, response.data[eventDataFields], response.data.request);
      }
      else {
        var payload = {};
        for (var i = 0; i < eventDataFields.length; i++) {
          if (eventDataFields[i] in response.data) {
            payload[eventDataFields[i]] = response.data[eventDataFields[i]];
          }
        }
        context.$events.$emit(eventName, payload, response.data.request);
      }
    }).catch(function (error) {
      if (apiData.api.command === 'countries-phone') { context.$store.commit('setLoadingCountryPhoneInfo', false); }
      console.log(error); // TODO: Maybe show a user-facing error if their request fails -- suggest reload page?
    });
  }
}

var api = new HHAPI();

// Send any javascript errors to our API for logging purposes and to debug web app
window.onerror = function (message, source, lineno, colno, err) {
  // Do not need to report these errors to the backend: https://stackoverflow.com/a/50387233/116223
  if (message.indexOf('ResizeObserver loop limit exceeded') >= 0) { return; }
  if (message.indexOf('ResizeObserver loop completed with undelivered notifications') >= 0) { return; }

  try {
    const error = {};
    error.url = window.location.href;
    error.type = 'javascript';
    error.message = message + '[' + [source, lineno, colno].join('; ') + ']';
    if (err && err.stack) { error.trace = err.stack; }

    api.logAppError(error);
  }
  catch (e) {
    if (process.env.DEBUGGING) { console.error(e); }
  }
}

// Send any unhandled promise errors to our API for logging purposes and to debug web app
window.addEventListener('unhandledrejection', function (event) {
  try {
    // If no reason is provided, don't bother sending the information... A lot of vue-router .push() events where
    // our beforeEach() guarding is canceling navigation and we don't catch that...
    if (!event.reason || (Array.isArray(event.reason) && event.reason.length === 0)) { return; }

    // Don't report any navigation duplicated errors, we aren't catching those from $route.push() calls
    if ('name' in event.reason && event.reason.name === 'NavigationDuplicated') { return; }

    // Do not report Network Error messages, as they were ballooning in size as the log-error request would fail
    // and trigger another Network Error. If we fix the recursiveness, then maybe we can report these again.
    if ('message' in event.reason && event.reason.message === 'Network Error') { return; }

    const error = {};
    error.url = window.location.href;
    error.type = 'promise-unhandledrejection';
    error.message = {};
    if ('reason' in event) { error.message.reason = event.reason; }
    if ('promise' in event) { error.message.promise = event.promise; }

    try {
      var err = new Error();
      if (err && err.stack) { error.trace = err.stack; }
    }
    catch (e) { }

    api.logAppError(error);
  }
  catch (e) {
    if (process.env.DEBUGGING) { console.error(e); }
  }
});

export default ({ app, router, Vue }) => {
  const EventBus = new Vue();
  api.$events = EventBus;
  api.$store = app.store;

  Vue.prototype.$hhAPI = api;

  // Send any vue errors to our API for logging purposes and to debug web app
  Vue.config.errorHandler = function (err, vm, info) {
    if (process.env.DEBUGGING) { console.error(err); }

    // In production, we send error reports back to the server
    try {
      const error = {};
      error.url = window.location.href; // string

      error.type = 'vue-' + info; // info = vue lifecycle (such as 'render')
      if (vm && vm.$vnode && vm.$vnode.tag) {
        error.type += '-' + vm.$vnode.tag; // name of vue component
      }

      error.message = err.message; // string
      error.trace = err.stack; // string

      api.logAppError(error);
    }
    catch (e) {
      if (process.env.DEBUGGING) { console.error(e); }
    }
  }
}
